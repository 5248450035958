import React, {  useContext } from 'react'
import Button from "../elements/Button";
import RemoveIcon from '-!svg-react-loader!../images/icons/remove.svg'
import styled from "styled-components"
import insertMarkup from "../utilities/insertMarkup"
import { CartContext } from "../contexts/cart-context";
import Box from "../elements/Box"


const GridContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr; 
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-areas: 
    "control preview";

  margin-bottom: 4rem;

  .control {
    display: flex;
    align-items: center;
  }

  .preview {
    font-size: 1.4rem;
  }
`;

const BibtexCartPreview = ({id, bibtextMarkuped}) => {
  const cartContext = useContext(CartContext);

  const handleDeleteProduct = () => {
    cartContext.dispatch({ type: 'DELETE_PRODUCT', id });
  };

  return (
    <>
      <GridContainer key={id}>
        <Box gridArea="control" className="control"><Button variant="secondary" onClick={handleDeleteProduct}><RemoveIcon/></Button></Box>
        <Box gridArea="preview" className="preview">{insertMarkup(bibtextMarkuped)}</Box>
      </GridContainer>
    </>

  )
}

export default BibtexCartPreview;