import React, { useContext } from 'react'
import { CartContext } from "../contexts/cart-context";
import Button from "./Button";
import RemoveIcon from '-!svg-react-loader!../images/icons/remove.svg'

const RemoveAllProductButton = () => {
  let NoCitations = [];
  const cartContext = useContext(CartContext);
  
  const handleDeleteAllProduct = () => {
    cartContext.dispatch({ type: 'DELETE_ALL_PRODUCT', NoCitations });
  };

  return (
    <Button variant="secondary" onClick={handleDeleteAllProduct}><RemoveIcon/>All</Button>
  );
}

export default RemoveAllProductButton;