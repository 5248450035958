import React from "react"
import styled from "styled-components"
import theme from "../general/theme"
import Portal from "./Portal";
import Box from "../elements/Box"
import Button from "../elements/Button";
import CloseIcon from '-!svg-react-loader!../images/icons/close.svg'
import OpenIcon from '-!svg-react-loader!../images/icons/open.svg'
import FileIcon from '-!svg-react-loader!../images/icons/file.svg'
import SaveIcon from '-!svg-react-loader!../images/icons/save.svg'
import useBoolean from "../hooks/useBoolean";
import {
  // Canon,
  // Trafalgar,
  // Paragon,
  GreatPrimer,
  // DoublePica,
  // BodyCopy,
  // SmallCap,
  // SmallPrint,
  // Code,
} from '../typography';

const { gray2, gray3, gray5, gray7, gray8, white, black } = theme.colours;
const r = theme.radius;

const StyledModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledModalCard = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30rem;
  min-height: 20rem;
  margin-bottom: 10rem;
  background-color: ${white};
  border-radius: 8px;
  padding: 2rem; 
  z-index: 10;
  height: auto;
`;

const StyledModalCardBox = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: center;

`;

const Background = styled.div`
  position: fixed;
  background-color: ${black};
  opacity: 0.5;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const Content = styled.div`
  position: relative;
  overflow: auto;
  max-height: 80vh;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0rem 4rem 2rem;

  .download-link {
    color: ${gray7};
    backgroundColor: ${gray2},
    padding: '1rem 2rem',
    margin: '1rem 0.5rem',
    fontWeight: '500',
    fill: ${gray7},
    // border: 1px solid ${gray5},
    borderRadius: ${r[6]},
    '&:hover': {
      color: ${gray8},
      backgroundColor: ${gray3},
      fill: ${gray8},
    },
  }
`;

const DownloadBox = styled.div`
  display: flex;
  a {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const CloseButton = styled.button`
  position: relative;
  // top: 1rem;
  // right: 4rem;
  z-index: 10;
  &:hover {
    cursor: pointer;
  }
`;

const Modal = ({ OpenButtonText, Icon, ButtonVariant, Type, DataUri, children }) => {
  const [isVisible, showModal, hideModal] = useBoolean(false);
  
  if (isVisible) {
    return (
      
      <Portal>
        <StyledModalWrapper>
          <StyledModalCard>
            <StyledModalCardBox>
              <ModalHeader>
                <DownloadBox>
                { Type === 'cart' ?
                  <a className="download-link" href={DataUri} download="agewell-bibtex.bib">
                    <GreatPrimer pr="2rem">Download Bibtex</GreatPrimer>
                    <SaveIcon />
                  </a>
                : ''
                }
                </DownloadBox>
                <Box>
                  <CloseButton onClick={hideModal}><Box p="0.5rem"><CloseIcon/></Box></CloseButton>
                </Box>
              </ModalHeader>
              <Content>
                { children }
              </Content>
            </StyledModalCardBox>
          </StyledModalCard>
          <Background onClick={hideModal}/>
        </StyledModalWrapper>
      </Portal>
    )
    } else {
      return (
      <Button variant={ButtonVariant} onClick={showModal}>
        { OpenButtonText  }
        { Icon === 'file' ? <Box display="inline-block" p="0.5rem 0.5rem 0.5rem 0" width="3rem"><FileIcon /></Box> 
        : Icon === 'open' ? <OpenIcon /> 
        : ''
        }
      </Button>)}
}


export default Modal;