import React from "react"  
import { graphql, StaticQuery } from "gatsby"  
import Search from "./Search"  
  
const SearchBar = (props) => {  
  return (  
    <StaticQuery  
      query={graphql`  
          query SearchIndexQuery {  
            siteSearchIndex {  
              index  
            }  
          }  
        `}  
      render={data => (  
        <Search handleNewCitations={props.handleNewCitations} searchIndex={data.siteSearchIndex.index}/>  
      )}  
    />  
  )  
}

export default SearchBar;