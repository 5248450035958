import React, { useContext, useEffect } from 'react'
import Modal from "./Modal"
import { CartContext } from "../contexts/cart-context";
import Box from "../elements/Box"
import styled from "styled-components"
import theme from "../general/theme"
import BibtexCartPreview from "./BibtexCartPreview"

const { white, gray2, gray3, gray5, gray7, gray8 } = theme.colours;
const r = theme.radius;

const StyledCart = styled.div`
  .download-link {
    color: ${gray7};
    backgroundColor: ${gray2},
    padding: '1rem 2rem',
    margin: '1rem 0.5rem',
    fontWeight: '500',
    fill: ${gray7},
    // border: 1px solid ${gray5},
    borderRadius: ${r[6]},
    '&:hover': {
      color: ${gray8},
      backgroundColor: ${gray3},
      fill: ${gray8},
    },

  }
`;

const StyledCounter = styled.div`
  background-color: ${white};
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  position: absolute;
  bottom: -1.2rem;
  left: -1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;


function buildBibtexPreview (product) {

  const id = product.id;
  const bibtex = product.bibtex;
  const bibtextMarkuped = product.bibtextMarkuped;

  // console.log('abstract in buildcitationcart-----', id);
  return (
    <BibtexCartPreview key={id} id={id}  bibtex={bibtex} bibtextMarkuped={bibtextMarkuped} />
  )
}

function buildBibtexDownload (product) {
  const bibtex = product.bibtex;
  // concatenatedBibtex.push(bibtex);
  return bibtex
}
 

const Cart = () => {
  const cartContext = useContext(CartContext);
  // console.log(cartContext);
  
  const productList = cartContext.state.products;
  const cartCounter = productList.length;
  const customBibtexDownload = productList.map(buildBibtexDownload).join()  

  var dataUri = "data:," + customBibtexDownload;

  useEffect(() => {
  }, [cartContext.state.products]);


  return (
    <>
      <Modal OpenButtonText="" Icon="file" ButtonVariant="primary" Type="cart" DataUri={dataUri}>
        <Box px="4rem" py="4rem" maxWidth="670px" color={gray7}>
          <StyledCart>
            { productList.map(buildBibtexPreview) }
          </StyledCart>
        </Box>
      </Modal>
      <StyledCounter>{cartCounter}</StyledCounter>
    </>
  )
} 


export default Cart;