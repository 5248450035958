import React from 'react'
import CitationCard from "../components/CitationCard"
import buildMarkup from "../utilities/buildMarkup"

const generateKey = (pre) => {
  return `${ pre }_${ new Date().getTime() }`;
}

function buildCitationCard (citation) {
  let uid = generateKey('cit');
  let id = citation.node.id || '';
  let bibtex = citation.node.bibtex || null;
  let bibtextMarkuped = buildMarkup(bibtex);
  let abstract = citation.node._rawAbstract || null;

  return (
    <CitationCard id={id} abstract={abstract} bibtex={bibtex} bibtextMarkuped={bibtextMarkuped} key={uid} />
  )
}

export default buildCitationCard;