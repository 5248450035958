import React, { useReducer } from 'react';
import styled from "styled-components"
import theme from "../general/theme"
import Layout from "../components/Layout"
import SearchBar from "../components/SearchBar"
import Cart from "../components/Cart"
import { StaticQuery, graphql } from "gatsby"
import Box from "../elements/Box"
import AddAllProductButton from "../elements/AddAllProductButton"
import RemoveAllProductButton from "../elements/RemoveAllProductButton"
import { Paragon } from '../typography';
import buildCitationCard from "../utilities/buildCitationCard"
import { CartContext } from "../contexts/cart-context";
import cartCounterReducer from "../reducers/cartCounterReducer";
import productsReducer from "../reducers/productsReducer";
import useCombinedReducers from "../hooks/useCombinedReducers";

const { gray7 } = theme.colours;

const SubNavContainer = styled.div`
display: grid;
grid-template-rows: auto;
grid-template-columns: 3fr 1fr 1fr 1fr; 
grid-column-gap: 2rem;
grid-row-gap: 2rem;
grid-template-areas: 
  "search add-all remove-all cart";
margin-bottom: 4rem;

  .search-item{
    align-self: center;
  }

  .add-all{
    align-self: center;
    justify-self: end;
  }

  .remove-all{
    align-self: center;
    justify-self: end;
  }

  .cart-item{
    align-self: center;
    justify-self: end;
    position: relative;

    button {
      margin: 0;
    }
  }
`;

const ResultContent = styled.div`
  position: relative;
  overflow: auto;
  max-height: 80vh;
`;

const TextBlock = styled.div`
    max-width: 850px;
    margin: 0 auto;
    
    .description {
      text-align: justify; 
    }
`;

const KnowledgeRepositoryPage = ({ data }) => {
  // Get all citations from CMS
  const AllCitations = data.allSanityCitation.edges;
  // console.log(AllCitations);

  // searchReducer lives here because it needs AllCitations
  function searchReducer(state, action) {
    switch (action.type) {
      case 'filterSearchResults':
        let rawSearchResults = action.rawSearchResults;
        // console.log(rawSearchResults);
        
        function getMatchingId (x) {
          let id = x.node.id
          // console.log(x);
          // console.log(id);
          let res = rawSearchResults.includes(id);
          // console.log(res);
          return res;
        };
        
        let searchResults = AllCitations.filter(getMatchingId);
        // console.log(searchResults);
        return searchResults;
  
      default:
        throw new Error();
    }
  }

  // Set citations as the initial state of the search. The searchResultsState is the result 
  // of searchReducer which filters AllCitations by id
  const [searchResultsState, searchResultsDispatch] = useReducer(searchReducer, AllCitations);

  // handleNewCitations is a function passed down to the search to surface the search results
  // rawSearchResults is an array of the result updated on every key up
  // it is then dispatched to the searchReducer to filter AllCitations
  const handleNewCitations = rawSearchResults => {
    searchResultsDispatch({ type: 'filterSearchResults', rawSearchResults })
  };
  

  const productsInitialState = [];
  const cartCounterInitialState = 0;

  
  // useCombinedReducers hook to combine the reducers states and dispatch action to pass it down to the CartContext
  const [state, dispatch] = useCombinedReducers({
    products: useReducer(productsReducer, productsInitialState),
    cartCounter: useReducer(cartCounterReducer, cartCounterInitialState),
  });
  

  return (
    <CartContext.Provider value={{state, dispatch}}>
      <Layout key="kdncksjdhdksfghd">
        <>
          <TextBlock key="3sdhdhydryryr">
            <Paragon className="description" color={gray7} mb="8rem" key="sajjfkhdhjjd">In response to inquiries from AGE-WELL members and affiliates about the needs of caregivers, a repository was constructed to house article references and abstracts produced and/or used by WP2 members between 2000-2020.</Paragon>
          </TextBlock>

          <SubNavContainer key="984jhsdfjgfgg">
            <Box className="search-item" gridArea="search" key="903dfbbdchfhd">
              <SearchBar handleNewCitations={handleNewCitations} key="98sdkjfisddsf"></SearchBar>
            </Box>
            <Box className="add-all" gridArea="add-all" key="39jdnfjdfhkshjdf">
              <AddAllProductButton AllCitations={AllCitations} key="93bvmsmxskaks" />
            </Box>

            <Box className="remove-all" gridArea="remove-all" key="jnsadf83">
              <RemoveAllProductButton key="93njdfbduf" />
            </Box>

            <Box className="cart-item" gridArea="cart" key="234jjdfdfyuf">
              <Cart key="l8sdkjfi8dyuf" />
            </Box>
          </SubNavContainer>
          <ResultContent key="23mwkdkdfyuf">
            { searchResultsState.map(buildCitationCard) }
          </ResultContent>
        </>
      </Layout>
    </CartContext.Provider>
  )
}


export default props => (
  <StaticQuery
    query={graphql`
      query allCitations {
        allSanityCitation {
          edges {
            node {
              bibtex
              _rawAbstract(resolveReferences: {maxDepth: 10})
              id
            }
          }
        }
      }
    `}
    render={data => <KnowledgeRepositoryPage data={data} {...props} />}
  />
)
