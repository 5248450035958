
import buildMarkup from "../utilities/buildMarkup"

function productsReducer(state, action) {
  switch (action.type) {
    case 'CREATE_PRODUCT':
      const checkId = (product) => product.id === action.id;
      if (!state.some(checkId)) {
          // console.log(state);
      let updatedProducts = [
        ...state,
        {
          id: action.id,
          bibtex : action.bibtex,
          bibtextMarkuped : action.bibtextMarkuped
        }
      ]
      
      state = updatedProducts;
      
      // console.log(updatedProducts);
      return state;

    } else {
        return state
    }

    case 'CREATE_ALL_PRODUCT':
      // Create product object utility
      function getAllProducts (x) {
        let productItem = 
          {
            id: x.node.id,
            bibtex : x.node.bibtex,
            bibtextMarkuped : buildMarkup(x.node.bibtex),
          }
        return productItem;
      }

      const AllCitations = action.AllCitations;
      
      if ( AllCitations !== null ) {

        let updatedProducts = [
          ...AllCitations.map(getAllProducts)
        ]
        
        state = updatedProducts;
        
        // console.log(updatedProducts);
        return state;

    } else {
        return state
    }
    
    case 'DELETE_ALL_PRODUCT':

      const NoCitations = action.NoCitations;
      
      if ( NoCitations !== null ) {

        let updatedProducts = [
          ...NoCitations
        ]
        
        state = updatedProducts;
        
        // console.log(updatedProducts);
        return state;

    } else {
        return state
    }

    case 'DELETE_PRODUCT':
      let updatedProducts = [
          ...state.filter(product => product.id !== action.id),
      ]
      state = updatedProducts;
      return state

    default:
      return state;
  }
}

export default productsReducer;