
const cartCounterReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_COUNTER':
      let productCount = 0;
      let countToAdd = state.cartCounter;
      
      if (countToAdd === productCount) {
        return state;
      } else {
        let addedCount = countToAdd + 1;
        state = addedCount;
        return state;
      }

    case 'REMOVE_COUNTER':
      let countToRemove = state;

      if (countToRemove === 0) {
        return state;
      } else {
        let removedCount = countToRemove - 1;
        state = removedCount;
        return state;
      }

    default:
      return state;
  }
}

export default cartCounterReducer;