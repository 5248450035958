import React, { Component } from 'react';
import { Index } from 'elasticlunr';
import Box from "../elements/Box"
import styled from "styled-components"
import theme from "../general/theme"
import SearchIcon from '-!svg-react-loader!../images/icons/search.svg'
import { debounce } from 'lodash';
const { white, gray7 } = theme.colours;



const InputBox = styled.div`
  
  @supports(-webkit-appearance: none) or (-moz-appearance: none) {
    input[type='text'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 100%;
      border-radius: 8px;
      background-color: ${white};
      display: flex;
      flex: 1;
    }
  }

  svg {
    fill: ${gray7};
  }

  span {
    display: inline-block;
    padding-right: 1rem;
    color: ${gray7};
  }

  label {
    display: flex;
    align-item: center;
  }

`;

// Graphql query used to retrieve the serialized search index.
export const query = graphql`
    query SearchIndexExampleQuery {
        siteSearchIndex {
            index
        }
    }
`;

export default class Search extends Component {  
  state = {  
    query: ``,  
    results: []  
 }  

 
  
 render() {  
    
    return (  
      <InputBox>  
      {/* <form onSubmit={this.handleSubmit}> */}
        <label><Box display="inline-block" p="0.5rem 0.5rem 0.5rem 0" width="3rem"><SearchIcon/></Box>
          <input type="text" value={this.state.query} onChange={this.search} name="t1" id="t1" /> 
        </label> 
        <ul>
        {/* {console.log('search state in search component', this.state.results)}   */}
          {/* {this.state.results.map(res => (  
            <li key={res.id}>  
              <p>{res.id}</p>
            </li>  
          ))}   */}
        </ul>  
        {/* </form> */}
      </InputBox>  
    )  
  }  
  
  getOrCreateIndex = () => {  
    return this.index  
      ? this.index  
      : // Create an elastic lunr index and hydrate with graphql query results  
      Index.load(this.props.searchIndex)  
  }  


  search = evt => { 
    let now = Date.now();
    let LastSearchEvent = parseInt(sessionStorage.getItem('LastSearchEvent') || 0)
    // console.log(LastSearchEvent);
    const debounceTime = 600;
    let nextAllowedSearchEvent = (LastSearchEvent + debounceTime);
    // console.log(nextAllowedSearchEvent);
    const query = evt.target.value  
    this.index = this.getOrCreateIndex()  
    
    
    this.setState({  
      query,  
      // Query the index with search string to get an \[\] of IDs  
      results: this.index  
      .search(query, { expand: true })  
      // Map over each ID and return the full document  
      .map(({ ref }) => {  
        return this.index.documentStore.getDoc(ref)  
      }),  
    });
    
    if (now >= nextAllowedSearchEvent) {
      // console.log('searching');
      // document.body.classList.add('progress');
      const handleNewCitations = this.props.handleNewCitations;
      let searchResults = [];
      this.state.results.map(res => searchResults.push(res.id))
      // console.log(searchResults);
      handleNewCitations(searchResults);
    } else {return;}

    sessionStorage.setItem('LastSearchEvent', `${Date.now()}`)

  }  
}