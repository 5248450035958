import buildCitation from "../citations/lib/buildCitation"
const Cite = require('citation-js');


function buildMarkup(bibtextEntry) {
  let cit = new Cite(bibtextEntry);
  let bibtexMarkuped = buildCitation(cit);  
  return bibtexMarkuped;
}

export default buildMarkup;