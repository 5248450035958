import React, { useContext } from 'react'
import { CartContext } from "../contexts/cart-context";
import Button from "../elements/Button";
import AddIcon from '-!svg-react-loader!../images/icons/add.svg'

const AddAllProductButton = (props) => {
  let AllCitations = props.AllCitations
  const cartContext = useContext(CartContext);
  
  const handleCreateAllProduct = () => {
    cartContext.dispatch({ type: 'CREATE_ALL_PRODUCT', AllCitations });
  };

  return (
    <Button variant="secondary" onClick={handleCreateAllProduct}><AddIcon/>All</Button>
  );
}

export default AddAllProductButton;