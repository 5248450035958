import React, { useContext } from 'react'
import { CartContext } from "../contexts/cart-context";
import styled from "styled-components"
import theme from "../general/theme"
import Modal from "../components/Modal"
import Button from "../elements/Button";
import insertMarkup from "../utilities/insertMarkup";
import Box from "../elements/Box"
import { BodyCopy } from '../typography';
import AddIcon from '-!svg-react-loader!../images/icons/add.svg'


const BlockContent = require('@sanity/block-content-to-react')
const { white, gray7, gray8 } = theme.colours;
  
const CitationSection = styled.section`
  .abstract {
    text-align: justify;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; 
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-areas: 
    "citation citation citation citation citation citation"
    "abstract bibtex . . . .";
`;

const serializers = {
  types: {
    abstract: props => (
    <BodyCopy className="abstract">{props.children}</BodyCopy>
    )
  }
}
  

const CitationCard = (props) => {
  let id = props.id;
  let bibtex = props.bibtex;
  let abstract = props.abstract || '';
  let bibtextMarkuped = props.bibtextMarkuped;

  const cartContext = useContext(CartContext);
  
  const handleCreateProduct = () => {
    cartContext.dispatch({ type: 'CREATE_PRODUCT', id, bibtex, bibtextMarkuped });
    cartContext.dispatch({ type: 'ADD_COUNTER' });
  };
  
  // const handleDeleteProduct = () => {
  //   cartContext.dispatch({ type: 'DELETE_PRODUCT', id });
  //   cartContext.dispatch({ type: 'REMOVE_COUNTER' });
  // };

  
  return (
    <CitationSection key={id}>
      <Box pt="3rem" pr="3rem" pb="2rem" pl="3rem" background={white} borderRadius="8px" mb="4rem">
        <GridContainer>
          <>
            <Box gridArea="citation"><BodyCopy color={gray8}>{insertMarkup(bibtextMarkuped)}</BodyCopy></Box>
            <Box gridArea="abstract">
              <Modal OpenButtonText="" Icon="open" ButtonVariant="secondary">
                <Box px="4rem" py="6rem" maxWidth="670px" color={gray7}>
                  <BlockContent blocks={abstract && abstract} serializers={{types: {block: serializers.types.abstract}}} />
                </Box>
              </Modal>
            </Box>
            <Box gridArea="bibtex"><Button variant="secondary" onClick={handleCreateProduct}><AddIcon/></Button></Box>
          </>
        </GridContainer>
      </Box>
    </CitationSection>
  );
}

export default CitationCard;