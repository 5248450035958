import styled from "styled-components"
import theme from "../general/theme"
import {
  variant,
  compose,
  space,
  layout,
  color,
  flexbox,
  grid,
  background,
  border,
  position,
} from 'styled-system';
// import Box from "../elements/Box"
// import CloseIcon from '-!svg-react-loader!../images/icons/close.svg'
// import OpenIcon from '-!svg-react-loader!../images/icons/open.svg'
// import useBoolean from "../hooks/useBoolean";

const { gray2, gray3, gray7, gray8 } = theme.colours;
const { navigation } = theme.fontFamilies;
const r = { ...theme.radius };

const Button = styled('button')(
  compose(
    variant,
    space,
    layout,
    color,
    flexbox,
    grid,
    background,
    border,
    position
  ),
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    fontFamily: `${navigation}`,
    fontSize: [3, 4, 5],
    cursor: 'pointer',
    textAlign: 'center',
    transition: 'background 150ms ease-in-out, transform 150ms ease',
  },
  variant({
    scale: 'buttons',
    variants: {
      primary: {
        color: `${gray7}`,
        backgroundColor: `${gray2}`,
        padding: '1.5rem 4rem',
        margin: '1rem 0.5rem',
        fontWeight: '500',
        fill: `${gray7}`,
        // border: `1px solid ${gray5}`,
        borderRadius: `${r[6]}`,
        '&:hover': {
          color: `${gray8}`,
          backgroundColor: `${gray3}`,
          fill: `${gray8}`,
          // border: `1px solid ${white}`,
        },
      },
      secondary: {
        color: `${gray7}`,
        backgroundColor: `${gray2}`,
        padding: '1rem 2rem',
        margin: '1rem 0.5rem',
        fontWeight: '500',
        fill: `${gray7}`,
        // border: `1px solid ${gray5}`,
        borderRadius: `${r[6]}`,
        '&:hover': {
          color: `${gray8}`,
          backgroundColor: `${gray3}`,
          fill: `${gray8}`,
          // border: `1px solid ${white}`,
        },
      },
    },
  })
);

export default Button;