const buildCitation = function buildCitation(x) {
  // console.log(x);
  let markup = x.format('bibliography', {
    format: 'html',
    template: 'apa'
  })
  // console.log(markup);
  return markup
};

export default buildCitation;